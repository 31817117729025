
<script lang="jsx">
import ProductCardTitleLabel from './ProductCardTitleLabel.vue'
import DiscountLabelV2 from '../UI/DiscountLabelV2.vue'

// 商品项标题容器
export default {
  name: 'ProductItemGoodsTitleContainer',
  functional: true,
  inject: ['multiColumn', 'labelsFromKey', 'item', 'config', 'setAttrForAnalysis', 'finalPriceAndDiscountInfo'],
  components: { ProductCardTitleLabel },
  props: {},
  render(h, { injections, slots }) {
    const finalPriceAndDiscountInfo = injections?.finalPriceAndDiscountInfo ?? {} 

    const item = injections.item ?? {}
    const { multiColumn, showDiscountLabelBeforeGoodsName, showDiscountLabelBeforeGoodsNameHighPriority, showDiscountLabelAfterPrice, showTitle, showTitleTwoLine } = injections.config ?? {}

    // 是否展示商品名称前标签 new hot
    const hasOwnPropertyShowGoodsNameLabel = injections.config?.hasOwnProperty('showGoodsNameLabel')
    const showGoodsNameLabel = !hasOwnPropertyShowGoodsNameLabel || (hasOwnPropertyShowGoodsNameLabel && injections.config.showGoodsNameLabel)

    const { finalDiscount, priceStateCollection, isSpecialDeSuggested } = finalPriceAndDiscountInfo || {}

    // 商品名称前的标签
    const goodsNameLabelUI = (() => {
      if (slots().beforeTitle) {
        return slots({ item }).beforeTitle
      } 

      // 德国合规多行场景不展示折扣标签
      const isDeMultiColumnDiscount = isSpecialDeSuggested && multiColumn

      // 德国合规价，写死折扣标签在标题左边
      const deShowDiscountLabel = isSpecialDeSuggested && ( showDiscountLabelBeforeGoodsName || showDiscountLabelAfterPrice )

      // 商品名称前折扣标签
      const showDiscountLabel = (deShowDiscountLabel || showDiscountLabelBeforeGoodsName) 
        && finalDiscount?.show && !priceStateCollection.hideDiscountLabel && !isDeMultiColumnDiscount

      const labelsFromKey = injections.labelsFromKey
      // 商品名称前new,hot等标签（backgroundColor, fontColor, goodsRange, labelLang, labelLangEn）
      const goodsNameLabel = item?.[labelsFromKey]?.goodsNameLabel ?? {}

      // 默认商品名称前(new,hot等)标签 优先级高于 折扣标签
      let compArr = ['titleLabel', 'discountLabel']
      // 组件映射
      const mapInfo = {
        discountLabel: {
          comp: (
            <div class="discount-label">
              <DiscountLabelV2 position="title" />
            </div>
          ),
          hasData: showDiscountLabel
        },
        titleLabel: {
          comp: <ProductCardTitleLabel goods-name-label={goodsNameLabel}></ProductCardTitleLabel>,
          hasData: showGoodsNameLabel ? goodsNameLabel?.labelLang : false
        }
      }

      // 折扣标签优先级更高
      if (showDiscountLabelBeforeGoodsNameHighPriority) {
        compArr = compArr.reverse()
      }

      // 商品名称前展示的内容UI
      let goodsNameLabelUI = null
      let labelType = ''
      for (let i = 0; i < compArr.length; i++) {
        const key = compArr[i]
        if (mapInfo[key]['comp'] && mapInfo[key]['hasData']) {
          goodsNameLabelUI = mapInfo[key]['comp']
          labelType = key
          break
        }
      }

      // 商品名称前标签埋点
      if (goodsNameLabelUI && labelType == 'titleLabel') {
        const labelLangEn = goodsNameLabel?.labelLangEn?.toLowerCase()
        injections.setAttrForAnalysis((el) => {
          el.setAttribute('data-title-label', goodsNameLabel?.appTraceInfo || `show_title_label_${goodsNameLabel.labelId}_${labelLangEn}`)
        })
      }

      return goodsNameLabelUI
    })()

    // 商品名称
    const goodsName = injections?.item?.goods_name ?? ''
    
    return goodsName && (showTitle || showTitleTwoLine) ? (
      <div class={[
        'product-card__goods-title-container',
        {
          'product-card__goods-title-container_twoline': showTitleTwoLine,
        }
      ]}>
        <div class="product-card__goods-title-content">
          {
            goodsNameLabelUI ? (
              <div class="tag-wrap">
                { goodsNameLabelUI }
              </div>
            ) : ''
          }
          { goodsName }
        </div>
      </div>
    ) : ''
  }
}
</script>

<style lang="less">
.product-card__goods-title-container {
  position: relative;
  display: flex;

  max-width: 100%;
  max-height: .4rem;
  overflow: hidden;

  word-break: break-all;
  
  font-size: .32rem;
  color: var(--sui-color-gray-dark-1, #000);

  &_twoline {
    max-height: .8rem;
    .product-card__goods-title-content {
      &::before {
        height: .8rem;
        // shape-outside: inset(calc(100% - .8rem) 0 0 0);
      }
    }
  }

  .tag-wrap {
    float: left;
    clear: none;
    .product-card__discount {
      margin-right: 0.1067rem;
    }
  }
}
// 为了适配部分safari版本，自行实现省略号
.product-card__goods-title-content {
  line-height: .4rem;

  vertical-align: middle;
  &::before {
    content: "...";
    float: right;
    height: .4rem;
    display: flex;
    align-items: flex-end;
    // shape-outside: inset(calc(100% - .4rem) 0 0 0);
  }

  &::after {
    content: "";
    position: absolute;
    width: 999vh;
    height: 999vh;
    background: #fff;
    box-shadow: -2em 2em #fff;
  }

}
</style>
