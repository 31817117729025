
<script lang="jsx">
// <!-- 文本类型组件 -->

// 包含以下类型
// 快速发货
// 本地发货
// 促销活动类  buy more save more、free gift
// 通用角标类型（优选卖家等等）


export default {
  name: 'ProductCardLabelsText',
  functional: true,
  render(h, { props }) {

    const { text, icon, style, className, ellipsis, useItalic, suiIcon } = props?.textData ?? {}
    const _className = [`product-card__label-text tag-${className || ''}`]
    if (ellipsis) {
      _className.push('text-ellipsis')
    }

    return (
      <p 
        class={[
          'product-card__label-text',
          useItalic ? 'product-card__label-text_italic' : '',
          className ? `tag-${className}` : '',
        ]}
        style={style}
      >
        { icon 
          ? <img class='product-card__label-text-icon' src={icon} /> 
          : suiIcon 
            ? <i class={{ 'sh_m_sui_icon': true, [suiIcon]: suiIcon }}></i> 
            : null 
        }
        <span class='product-card__label-text-content'>{ text }</span>
      </p>
    )
  }
}

</script>

<style lang="less">
.product-card {
  &__label-text {
    display: flex;
    align-items: center;
    padding: 0 .08rem;
    height: .373rem;
    line-height: .373rem;
    border-radius: .053rem;
    font-size: 0.266rem;
    color: @sui_color_gray_dark2;
    font-weight: 400;
    vertical-align: middle;
    &_italic {
      font-style: italic;
    }
  
    &.tag-QuickShip {
      background-color: var(--sui_color_ship_bg, #ECFCF3);
    }
    &.tag-QuickShipNew {
      border-radius: 0.053rem;
      border: 1px solid rgba(25, 128, 85, 0.20);
      background: #ECFCF3;
      color: #198055;
    }
    &.tag-Seller {
      background-color: var(--sui_color_Preferred_bg, #FFF5E7);
    }
    &.tag-Activity {
      background-color: var(--sui_color_sale_bg, #FFF3F1);
    }
    &.tag-qualityShop {
      padding-left: 0;
      color: var(---sui_color_main, #222);
      font-size: .293rem;
      font-weight: 500;
    }
    .sh_m_sui_icon {
      margin-right: 0.053rem;
    }
  }

  &.text-ellipsis {
    .product-card__label-text-content {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.product-card__label-text-content {
  .text-overflow();
  flex: 1;
  min-width: 0;
  line-height: 1.2em;
}
.product-card__label-text-icon {
  width: 0.266rem;
  height: 0.266rem;
  margin-right: 0.026rem;
}
</style>
