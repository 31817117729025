var render, staticRenderFns
import script from "./index.vue?vue&type=script&lang=jsx&"
export * from "./index.vue?vue&type=script&lang=jsx&"
import style0 from "./index.vue?vue&type=style&index=0&id=6748822f&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/.pnpm/vue-loader@15.10.0_css-loader@6.8.1_ejs@2.7.2_lodash@4.17.21_vue-template-compiler@2.7.14_webpack@5.86.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6748822f",
  null
  
)

component.options.__file = "index.vue"
export default component.exports