<script lang="jsx">
import { columnToCutImageSize } from '../../js/constant'
import { containerRatio } from 'public/src/pages/components/CropImageContainer/index.js'

export default {
  name: 'ProductCardMaskLayer',
  functional: true,
  inject: {
    labelsFromKey: {
      default: ''
    },
    constantData: {
      from: 'constantData',
      default: () => ({})
    },
    item: {
      from: 'item',
      default: () => ({})
    },
    setAttrForAnalysis: {
      default: (() => {})
    },
  },
  props: {
    mainImg: {
      type: String,
      default: ''
    },
    column: {
      type: [String, Number],
      default: 2
    },
    // @example '1-1'
    cropRate: {
      type: String,
      default: ''
    },
    autoCropImage: {
      type: Boolean,
      default: false
    },
  },
  render(h, { props = {}, injections = {} }) {
    const { mainImg, column, cropRate, autoCropImage } = props
    const { constantData, item, labelsFromKey, setAttrForAnalysis } = injections || {}
    const maskLayer = item?.[labelsFromKey]?.maskLayer ?? {}

    const {
      maskLayerSrc: maskImg,
      maskLayerWidth: maskWidth,
      maskLayerHeight: maskHeight,
      goodsRange: maskTagInfo,
      appTraceInfo
    } = maskLayer ?? {}

    const fixedRatio = cropRate ? cropRate : (autoCropImage ? '' : '3-4')

    if (
      maskImg &&
      maskWidth &&
      maskHeight &&
      containerRatio(fixedRatio, mainImg) === maskHeight / maskWidth
    ) {
      const imgDesignWidth = columnToCutImageSize[`${column}`] || 0

      setAttrForAnalysis((el) => {
        if (appTraceInfo) {
          el.setAttribute('data-mask-layer', appTraceInfo)
        } else if (maskTagInfo?.tagId) {
          el.setAttribute('data-mask-layer', `show_mask_layer_${maskTagInfo?.tagId}`)
        }
      })

      return (
        <img
          src={constantData.LAZY_IMG_PIXEL}
          data-src={maskImg}
          data-design-width={imgDesignWidth}
          class="lazyload product-item__mask-layer"
        />
      )
    }
    return <div></div>
  }
}
</script>

<style lang="less" scoped>
.product-item {
  &__mask-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 0;
  }
}
</style>
