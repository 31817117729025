
<script lang="jsx">
import { getQueryString, stringifyQueryString } from '@shein/common-function'
import { getRankProductsUrl } from 'public/src/pages/common/ranking_list/rankTool'

const { langPath } = gbCommonInfo
// <!-- 榜单组件 -->
export default {
  name: 'ProductCardSellingPropositionRank',
  functional: true,
  inject: {
    config: {
      default: () => ({})
    },
  },
  render(h, { props, data, injections }) {
    const { disableApartFromTheDetailsJump, rankingLabelWithoutIcon } = injections?.config || {}

    const { bffGoodsInfo, goods_id } = props?.item || {}

    // eslint-disable-next-line no-unused-vars
    const { icon, score, rankTypeText, composeIdText, color = '', backgroundColor = '' } = props?.rankingList ?? {}

    const rankTitleText = bffGoodsInfo ? rankTypeText : `#${score} ${rankTypeText}`

    const rankingLink = async () => {
      if (typeof window === 'undefined') return ''
      let ici = getQueryString({ key: 'ici' }) || ''
      let { contentCarrierId, itemInfoId, carrierTempId, title, activityName, rankingType, carrierSubType } = props?.rankingList ?? {}
      let params = {
        page_from: 'detail',
        contentCarrierId,
        item_id: itemInfoId,
        page_title: title,
        rankTypeText: encodeURIComponent(title),
        templateId: carrierTempId,
        currentItem: goods_id,
        ici: encodeURIComponent(ici),
        scene_id: 52,
        src_module: 'ranking_list_label',
        src_identifier: `ri=${carrierSubType}\`rn=${rankingType}\`ps=${ props?.index + 1}\`jc=${contentCarrierId}`,
        src_tab_page_id: window?.getSaPageInfo?.tab_page_id || '',
        goods_id,
      }
      const rankProductsUrl = await getRankProductsUrl({
        carrierSubType,
        langPath,
        params,
        from: 'listLabel',
      })
      if (rankProductsUrl) {
        return {
          isNewPage: false,
          link: rankProductsUrl,
        }
      }
      const actUrl = `${langPath}/campaign/${activityName}?${stringifyQueryString({
        queryObj: params,
      })}`
      return {
        isNewPage: true,
        link: actUrl,
      }
    }

    const handleClickRankingList = async (event) => {
      if (disableApartFromTheDetailsJump || !props?.rankingLabelClickable) {
        return false
      } else {
        event?.stopPropagation()
      }
      const {
        link,
        isNewPage,
      } = await rankingLink()
      if (link) {
        let { contentCarrierId, rankingType, carrierSubType, cateIds, score } = props?.rankingList ?? {}
        daEventCenter.triggerNotice({
          daId: '2-3-38',
          bindData: {
            src_module: 'ranking_list_label',
            src_identifier: `ri=${carrierSubType}\`rn=${rankingType}\`ps=${props?.index + 1}\`jc=${contentCarrierId}\`cate_id=${cateIds || '-'}\`goods_rank=${score}`,
            content_id: contentCarrierId,
            goods_id: goods_id ?? '',
          }
        })
        if (isNewPage || !props.router) {
          location.href = link
        } else {
          props.router.push(link)
        }
      }
    }
    // TODO: 后续需要接入榜单背景颜色 字体颜色取CCC数据
    const containerStyle = {
      // color
    }
    const mainStyle = {
      // backgroundColor
    }
    const { PUBLIC_CDN = '', GB_cssRight  } = props?.constantData || {}
    const rankSplitImgSrc = `${PUBLIC_CDN}/pwa_dist/images/sui_pic_List@2x-46815e7bc6.png`

    
    const rankLink = (
      <div 
        class={{
          'product-card__selling-proposition-rank': true,
          'product-card__selling-proposition-ranknew': rankingLabelWithoutIcon,
          'product-card__selling-rank-link': true
        }}
        style={[containerStyle, data.style, data.staticStyle]}
        vOn:click={handleClickRankingList}
      >
        {!rankingLabelWithoutIcon && (
          <img
            class="rank-logo"
            src={icon}
          />
        )}
        <div 
          class={{
            'rank-main-old': !rankingLabelWithoutIcon,
            'rank-main-new': rankingLabelWithoutIcon,
            'rank-main': true
          }}
          style={mainStyle}
        >
          <span class={ rankingLabelWithoutIcon ? 'rank-title-new' : 'rank-title-old' }>{rankTitleText}</span>
        </div>
        <span class={ rankingLabelWithoutIcon ? 'rank-sub-new' : 'rank-sub-old' }>
          {!rankingLabelWithoutIcon && (
            <img 
              class={{
                'rank-split-img': true,
                'rank-split-img_ar': GB_cssRight,
              }}
              src={rankSplitImgSrc} 
            />
          )}
          <span class="rank-sub-text">{composeIdText}</span>
          {props.rankingLabelClickable && (
            <img
              class={{
                'right-icon': true,
                'right-icon_ar': GB_cssRight,
              }}
              src="https://shein.ltwebstatic.com/svgicons/2024/09/12/17261113543302815309.svg"
            />
          )}
        </span>
      </div>
    )
    return rankLink
  }
}
</script>

<style lang="less">
.product-card__selling-proposition-rank {
  .flexbox();
  .align-center();
  flex-wrap: nowrap;
  padding-right: 4px; // 修复尾部展示不完全

  height: var(--selling-rank-height, 0.373rem);
  
  font-size: .293rem;

  & > span, .rank-main > * {
    margin-left: .106rem;
    line-height: 1em;
  }

  .rank-main {
    flex-wrap: nowrap;
    .flexbox();
    .align-center();
  }

  .rank-main-old {
    background: linear-gradient(90deg, #FFEDDC 25.19%, rgba(255, 249, 220, 0.20) 100%);
  }

  .rank-main-new {
    padding-right: 0.05rem;
    border-radius: 0.16rem 0.02667rem;
    background: #ffe8ae;
  }


  .rank-logo {
    width: .32rem;
  }

  .rank-title-old {
    padding-top: 0; // 修复榜单页面找相似弹窗样式覆盖
    white-space: nowrap;
    font-size: .293rem;
    color: #CC6E00;
    line-height: .35rem;
  }

  .rank-title-new {
    padding-top: 0; // 修复榜单页面找相似弹窗样式覆盖
    white-space: nowrap;
    font-size: 0.26667rem;
    line-height: .373rem;
    color: #C77210;
    font-weight: 700;
  }
  

  .rank-sub-old {
    white-space: nowrap;
    color: var(--sui-color-gray-dark-3, #767676);
  }

  .rank-sub-new {
    position: relative;
    display: flex;
    white-space: nowrap;
    margin-left: 0;
    background: #FFFBEB;
    height: .373rem;
    line-height: .373rem;
    padding-right: .32rem;
    color: #D58700;
  }

  span:last-child {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.product-card__selling-rank-link {
  margin-top: var(--selling-rank-margin-top, 0.08rem);
  .rank-main-old {
    position: relative;
    padding-left: 0.32rem;
    border-radius: .1867rem 0 0 .1867rem;
    background: linear-gradient(269deg, #FFE79F 5.63%, #FFEBAC 55.21%);
  }
  .rank-logo {
    position: absolute;
    z-index: @zindex-hack;
    height: .373rem;
  }
  .rank-split-img {
    position: relative;
    z-index: @zindex-hack;
    height: .35rem;
    &_ar {
      transform: rotate(180deg);
    }
  }
  .rank-title {
    line-height: .35rem;
  }
  .rank-sub-old {
    position: relative;
    display: flex;
    margin-left: 0;
    background: #fef7e2;
    height: .35rem;
    line-height: .35rem;
    padding-right: .32rem;
    color: rgba(204, 110, 0, 0.68);
  }
  .rank-sub-text {
    margin-left: 0.064rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .right-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 0.21333rem;
    height: 0.21333rem;
  }
  .right-icon_ar {
    transform: rotateY(180deg) translateY(-50%);
  }
}
.product-card__selling-proposition-ranknew {
  padding-right: 4px; // 修复尾部展示不完全
  font-size: 0.26667rem;
}
[mir='rtl'] {
  .product-card__selling-proposition-ranknew .rank-main {
    border-radius: 0.02667rem 0.16rem;
  }
}
</style>
